<template>
    <modal name="mypage-add-bank-modal" class="mypage-modal pw"
           :width="`100%`" :height="`100%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <member-write-component :member-type="'addAccount'"></member-write-component>
    </modal>
</template>

<script>
import MemberWriteComponent from "@/components/member/MemberWriteComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "MypageAddBankModal",
    mixins: [],
    components: {
        MemberWriteComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            pwd: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            $("body").removeClass("modal-open");
        },
        modalClose() {
            $("body").removeClass("modal-open");
            this.$modal.hide('mypage-add-bank-modal');
        },
        returnUrl(){
            return this.url
        },
        setData(key, value) {
            this[key] = value
        },
        checkSave() {

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
