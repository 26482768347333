<template>
    <form name="form_chk" method="post" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.c">
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" value="" />
        <input type="hidden" id="enc_data" name="enc_data" value="" />
        <input type="hidden" id="integrity_value" name="integrity_value" value="" />
    </form>
</template>

<script>
import {apiURL} from "@/api/config";
import EventBus from "@/utils/event-bus";
import cryptoJs from "crypto-js"
import util from "@/mixins/util";
import AuthMixins from "@/mixins/AuthMixins";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "MemberAuthenticationComponent",
    mixins: [AuthMixins, alertMixins],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{

        }
    },
    beforeRouterEnter() {},
    created() {
        let enc_data = this.$localStorage.get('enc_data');
        if(!util.isEmpty(enc_data)) {
            this.decryptData()
        }
        /*if(!util.isEmpty(this.$route.query) && !util.isEmpty(this.$route.query.enc_data)) {
            this.decryptData(this.$route.query);
        }
        if(!util.isEmpty(this.$route.query)) {
            this.$router.replace(this.$route.path);
        }*/
    },
    mounted() {
        EventBus.$on('runAuthenticatio', this.runAuthenticatio)
    },
    beforeDestroy() {
        EventBus.$off('runAuthenticatio')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        decryptData() {
            let encodeObj = this.$localStorage.get('AuthenticationObj');
            let objData = JSON.parse(atob(encodeObj));
            let enc_data = this.$localStorage.get('enc_data')
            // let objData = encodeObj;
            if(util.isEmpty(encodeObj) || util.isEmpty(objData.key) || util.isEmpty(objData.iv) || util.isEmpty(enc_data)) {
                this.failNiceAuth();
            }
            EventBus.$emit('apiLoading', true);
            let params = {
                data: enc_data,
                key: objData.key,
                iv: objData.iv,
            }
            this.$api.$member.getDecryptData(params).then(res => res.Data.Result).then(res => {
                EventBus.$emit('apiLoading', false);
                if(res.Info.type == 1 ) {
                    this.$localStorage.remove('AuthenticationObj')
                    this.$localStorage.remove('enc_data')
                    let token = this.$localStorage.get('token');
                    this.$store.dispatch('userInfo/setUserInfo', {token: token});
                    this.createAlert({
                        content: this.$t('authentication_success'),
                    });
                }else{
                    this.failNiceAuth();
                }

                // this.memberUpdate({})

            })
        },
        failNiceAuth() {
            this.errorAlert(this.$t('authentication_fail'));
            if(!util.isEmpty(this.$localStorage.get('AuthenticationObj'))) {
                this.$localStorage.remove('AuthenticationObj')
            }
            if(!util.isEmpty(this.$localStorage.get('enc_data'))) {
                this.$localStorage.remove('enc_data')
            }
        },
        runAuthenticatio() {
            let domain = window.location.origin;
            let params = {
                mb_no: this.UserInfo.mb_no,
                url : domain + '/auth/certification/result'
                //url : 'https://www.minglemint.com/auth/certification/result'
            };
            this.$api.$member.getEncryptData(params).then(res => res.Data.Result).then(res => {
                if(util.isEmpty(res.List)) {
                    this.errorAlert(this.$t('authentication_fail'));
                    return false;
                }
                //encode data;
                let encodeData = res.List;
                this.checkIdentity(res.List)

            })
        },
        checkIdentity(encodeData) {
            let token_version_id = encodeData.token_version_id;
            let enc_data = encodeData.enc_data;
            let integrity_value = encodeData.integrity_value;

            document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
			// document.form_chk_action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
            document.form_chk.token_version_id.value = token_version_id;
            document.form_chk.enc_data.value = enc_data;
            document.form_chk.integrity_value.value = integrity_value;
            let obj = {
                key: encodeData.key,
                iv: encodeData.iv,
                mb_no: this.UserInfo.mb_no,
            }
            let decodeObj = btoa(JSON.stringify(obj));
            this.$localStorage.set('AuthenticationObj', decodeObj)

            window.open('popupChk', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
            document.form_chk.target = "popupChk";
            document.form_chk.submit();
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
