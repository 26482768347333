<template>
    <!--    멤버 작성 디폴트 레이아웃-->
    <div class="member_write_wrap">
        <div class="user_wrap">
            <div class="user">{{ $t('id') }}</div>
            <div class="user_info id">
                {{ UserInfo.mb_id }}
            </div>
        </div>
        <div class="user_wrap">
            <div class="user">{{ $t('nick') }}</div>
            <div class="user_info" v-if="checkMemberData(UserInfo.mb_nick)">{{ UserInfo.mb_nick }}</div>
        <!--닉네임 수정 불가-->
<!--            <button class="modify_btn" @click="openModal('mypage-nick-modal')"-->
<!--                    v-if="checkMemberData(UserInfo.mb_nick)"></button>-->
<!--            <button class="enroll_btn" @click="openModal('mypage-nick-modal')" v-else>{{ $t('enroll') }}</button>-->
        </div>
        <div class="user_wrap" v-if="returnSocialType() === 'email'">
            <div class="user">{{ $t('password') }}</div>
            <div class="user_info id pw">********</div>
            <button class="modify_btn pw" @click="openModal('mypage-pw-modal')"></button>
        </div>
        <div class="user_wrap">
            <div class="user">{{ $t('phoneNumber') }}</div>
            <div class="user_info id" v-if="checkMemberData(UserInfo.mb_hp)">{{ UserInfo.mb_hp }}</div>
            <button class="modify_btn" @click="requestNiceModule('mypage-phone-modal')"
                    v-if="checkMemberData(UserInfo.mb_hp)"></button>
<!--            <button class="enroll_btn" @click="openModal('mypage-phone-modal')" v-else>{{ $t('enroll') }}</button>-->
            <button class="enroll_btn" @click="requestNiceModule()" v-else>{{ $t('enroll') }}</button>
        </div>
        <div class="user_wrap">
            <div class="user">{{ $t('name_authentication') }}</div>
            <div class="user_info" v-if="checkMemberData(UserInfo.mb_name)">{{ UserInfo.mb_name }}</div>
<!--            <button class="modify_btn" @click="openModal('mypage-authentication-modal')"
                    v-if="checkMemberData(UserInfo.mb_name)"></button>-->
<!--            <button class="enroll_btn" @click="requestNiceModule2()" v-else>{{$t('enroll') }}</button>-->
            <button class="enroll_btn" @click="requestNiceModule('mypage-authentication-modal')" v-if="!checkMemberData(UserInfo.mb_name)">{{$t('enroll') }}</button>
        </div>
        <div class="user_wrap">
            <div class="user">{{ $t('bank_add') }}</div>
            <div class="user_info" v-if="checkMemberData(UserInfo.Account)">
                {{ UserInfo.Account[0].ma_name }} /
                {{ UserInfo.Account[0].ma_bank }} /
                {{ UserInfo.Account[0].ma_account }}
            </div>
            <button class="modify_btn" @click="checkInsertBank('mypage-bank-modal')"
                    v-if="checkMemberData(UserInfo.Account)"></button>
            <button class="enroll_btn" @click="checkInsertBank('mypage-bank-modal')" v-else>{{ $t('enroll') }}</button>
        </div>
        <div class="validation pt5" v-if="UserInfo.g_idx != 2">{{ $t('account_warning') }}</div>
        <div class="user_wrap">
            <div class="user">{{ $t('r_nick') }}</div>
            <div class="user_info id" v-if="checkMemberData(UserInfo.Recommend)">{{ UserInfo.Recommend[0].mb_nick }}</div>
            <button class="enroll_btn" @click="openModal('mypage-referrer-modal')" v-show="!checkMemberData(UserInfo.Recommend)">{{ $t('enroll') }}</button>
        </div>
        <member-authentication-component></member-authentication-component>
        <mypage-add-bank-modal></mypage-add-bank-modal>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import MemberAuthenticationComponent from "@/components/member/MemberAuthenticationComponent";
import MypageAddBankModal from "@/components/modal/MypageAddBankModal";
import AuthMixins from "@/mixins/AuthMixins";

export default {
    name: "MemberWriteDefaultLayout",
    mixins: [alertMixins, AuthMixins],
    components: {
        MemberAuthenticationComponent,
        MypageAddBankModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData:{}
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('MemberWriteDefaultLayoutForceUpdate', this.layoutForceUpdate);
    },
    beforeDestroy() {
        EventBus.$off('MemberWriteDefaultLayoutForceUpdate');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal(name) {
            this.$modal.show(name);
            $("body").addClass("modal-open");
        },
        checkMemberData(data) {
            return !util.isEmpty(data);
        },
        layoutForceUpdate() {
            this.$forceUpdate()
        },
        requestNiceModule() {
            EventBus.$emit('runAuthenticatio')
        },
        checkInsertBank() {
            if(!this.isAuthenticated()) {
                this.openModal('mypage-authentication-modal')
            }else{
                this.openModal('mypage-add-bank-modal')
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
